/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/matrix_bg.jpg") + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container id="home">
            <div className="title-brand">
              <h1 className="presentation-title">Project Post COVID-19</h1>
              <div className="fog-low">
                <img alt="..." src={require("assets/img/fog-low.png")} />
              </div>
              <div className="fog-low right">
                <img alt="..." src={require("assets/img/fog-low.png")} />
              </div>
            </div>
            <h2 className="presentation-subtitle text-center">
              New Requirement For Internet Governance After The Pandemic
            </h2>
            <div className="projectSummary">The COVID-19 pandemic has demonstrated that the Internet,
            though it is deemed to be a non-exclusive good that is open for joint consumption, must be must be subjected to new rules. 
            In the context of the pandemic, misuse of the Internet and dissemination of fake news on the Internet have become serious hazards 
            which have jeopardized the efforts to contain the disease and even led to deaths. The effects of the pandemic and the situation it 
            creates induce policy makers to reconsider the way in which public and private entities provide and regulate the Internet. 
            This requires a common understanding on a global level which also needs to bridge the transatlantic divide on how to respect and 
            deal with privacy concerns. The authors have established a project group with several policy makers which will design formats to 
            involve leaders in business and government in a decision-making process.
            </div>
          </Container>
        </div>
        <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(" + require("assets/img/clouds.png") + ")",
          }}
        />
        <h6 className="category category-absolute">
          Designed and coded by{" "}
          <a
            href="https://www.eeurope.de"
            target="_blank"
          >
            <img
              alt="..."
              className="eeurope-logo"
              src={require("assets/img/eeurope-logo-white.png")}
            />
          </a>
        </h6>
      </div>
    </>
  );
}

export default IndexHeader;
