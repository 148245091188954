/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionContact() {
  return (
    <>
      <div
        className="section section-image section-login"
        style={{
          backgroundImage: "url(" + require("assets/img/login-image.jpg") + ")",
        }}
      >
          <Container id="contact">
            <div className="title">
              <h3>Project Team And Contacts</h3>
            </div>
            <div className="projectSummary" style={{fontWeight: "300"}}>Please contact our project lead by e-mail for any questions concerning cooperations.</div>
            <Row>
              <Col className="mr-auto" md="2" sm="3">
                <h4 className="images-title">Project Lead</h4>
                <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive"
                  src={require("assets/img/faces/dr-roland-bardy.jpg")}
                />
                <p className="text-center">Dr. Roland Bardy<br />
                <a href="mailto:rbardy@t-online.de">rbardy@t-online.de</a></p>
              </Col>
              <Col className="mr-auto" md="2" sm="3">
                <h4 className="images-title">Project Lead</h4>
                <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive"
                  src={require("assets/img/faces/dr-arthur-rubens.gif")}
                />
                <p className="text-center">Dr. Arthur Rubens<br />
                <a href="mailto:arubens@fgcu.edu">arubens@fgcu.edu</a></p>
              </Col>
            </Row>
          </Container>
        </div>
    </>
  );
}

export default SectionContact;
